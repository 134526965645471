<template>
  <div class="RegisterWrapper">
    <b-container fluid>
      <div class="RegisterInner">
        <b-row>
          <div>
            <b-modal
              v-model="show"
              id="modal-center"
              centered
              title=""
              hide-footer
              size="sm"
            >
              <template #modal-header>
                <h3 class="mx-auto text-primary">
                  {{ $t("LOGIN SUCCESSFUL") }}
                </h3>
              </template>
              <div class="mx-auto text-center mb-2 mt-2">
                <i class="fa fa-check-circle fa-5x text-primary"></i>
              </div>
            </b-modal>
          </div>
          <b-col cols="12" sm="6" class="d-flex">
            <div class="FormImg d-flex">
              <div class="my-3">
                <h3 class="mb-4">{{ $t("Login") }}</h3>
                <span class="mb-4 d-block">
                  {{ $t("You already have an account ?") }}
                </span>
                <b-button
                  variant="primary "
                  pill
                  class="rounded"
                  @click="login"
                >
                  {{ $t("Login with exist Account") }}
                </b-button>
              </div>
            </div>
          </b-col>
          <b-col cols="12" sm="6">
            <b-form class="p-2 mt-2">
              <b-row class="fullNameInputs">
                <b-col cols="12" sm="6">
                  <b-form-group id="input-group-1" label-for="firstNameI">
                    <b-form-input
                      id="firstNameI"
                      type="text"
                      placeholder="الأسم الأول"
                      class="rounded-form"
                      v-model.lazy="form.FirstNameI"
                      @blur="$v.form.FirstNameI.$touch"
                      :state="validateState('FirstNameI')"
                    ></b-form-input>
                    <b-form-invalid-feedback class="text-right mx-2"
                      >{{ $t("reqfirst") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="6">
                  <b-form-group id="LastNameI" label-for="LastNameI">
                    <b-form-input
                      id="LastNameI"
                      type="text"
                      placeholder="الأسم الأخير"
                      class="rounded-form"
                      v-model.lazy="form.LastNameI"
                      @blur="$v.form.LastNameI.$touch"
                      :state="validateState('LastNameI')"
                    ></b-form-input>
                    <b-form-invalid-feedback class="text-right mx-2"
                      >{{ $t("reqlast") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group id="emailInput" label-for="emailInput">
                <b-form-input
                  id="emailInput"
                  type="email"
                  placeholder="الإيميل"
                  class="rounded-form"
                  v-model.lazy="form.MailI"
                  @blur="$v.form.MailI.$touch"
                  :state="validateState('MailI')"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="!$v.form.MailI.required || !$v.form.MailI.email"
                  class="text-right mx-2"
                  >{{ $t("reqemail") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback v-else class="text-right mx-2"
                  >{{ $t("oldmail") }}
                </b-form-invalid-feedback>

                <!-- <span>Register Using mobile</span> -->
              </b-form-group>
              <!-- <b-form-group
                id="phoneNumberI"
                label-for="phoneNumberI"
            >
                <b-form-input
                    id="phoneNumberI"
                    type="text"
                    placeholder="رقم الموبايل"
                    class="rounded-form"
                ></b-form-input>
                <span>Register Using email</span>
            </b-form-group> -->
              <b-form-group id="usernameInput" label-for="usernameInput">
                <b-form-input
                  id="usernameInput"
                  type="text"
                  placeholder="أسم المستخدم"
                  class="rounded-form"
                  v-model.lazy="form.UserNameI"
                  @blur="$v.form.UserNameI.$touch"
                  :state="validateState('UserNameI')"
                >
                </b-form-input>
                <b-form-invalid-feedback
                  v-if="!$v.form.UserNameI.minLength"
                  class="text-right mx-2"
                  >{{ $t("usernamelength") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback v-else class="text-right mx-2"
                  >{{ $t("oldusername") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group id="emailInput" label-for="passwordI">
                <b-form-input
                  id="passwordI"
                  type="password"
                  placeholder="كلمة السر"
                  class="rounded-form"
                  v-model.lazy="form.PassI"
                  @blur="$v.form.PassI.$touch"
                  :state="validateState('PassI')"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="!$v.form.PassI.minLength"
                  class="text-right mx-2"
                  >{{ $t("passlength") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback v-else class="text-right mx-2"
                  >{{ $t("reqpass") }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="emailInput" label-for="password2I">
                <b-form-input
                  id="password2I"
                  type="password"
                  placeholder="كرر كلمة السر"
                  class="rounded-form"
                  v-model.lazy="form.Pass2I"
                  @blur="$v.form.Pass2I.$touch"
                  :state="validateState('Pass2I')"
                >
                </b-form-input>

                <b-form-invalid-feedback class="text-right mx-2"
                  >{{ $t("reqpass2") }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group>
                <div class="d-flex" style="align-items: baseline">
                  <b-form-checkbox
                    class="mx-1"
                    name="terms"
                    id="terms"
                    v-model.lazy="form.terms"
                    @blur="$v.form.terms.$touch"
                    :state="validateState('terms')"
                  ></b-form-checkbox>

                  <b-form-invalid-feedback class="text-right mx-2"
                    >{{ $t("reqpass") }}
                  </b-form-invalid-feedback>

                  <p>
                    أوافق على
                    <router-link :to="{ name: 'terms' }">{{
                      $t("Terms&Conds")
                    }}</router-link>
                  </p>
                </div>
              </b-form-group>
              <b-form-group>
                <b-button variant="primary" pill block @click="Register()">{{
                  $t("Register")
                }}</b-button>
              </b-form-group>
            </b-form>
            <div>
              <p class="text-center">أو</p>
            </div>
            <div class="mx-2 mb-3">
              <!-- <b-button  class="col-10 mx-auto" pill block style="background-color: #4267b2; border-color: #4267b2"
                @click="RegisterWithFacebook()">
                <div class="
                    
                    justify-content-center justify-content-between
                    align-items-center
                    mx-5
                  ">
                  <i class="fab fa-facebook-f p-1 px-2 rounded-circle bg-white mx-2" style="color: #4267b2"></i>{{
                      $t("LoginFace")
                  }}
                </div>
              </b-button>  -->
              <b-button
                class="col-10 mx-auto"
                pill
                variant="primary"
                block
                style="background-color: #dd4b39; border-color: #dd4b39"
                @click="RegisterWithGoogle()"
              >
                <div
                  class="
                    justify-content-center justify-content-between
                    align-items-center
                    mx-5
                  "
                >
                  <i
                    class="fab fa-google mx-1 p-1 rounded-circle bg-white mx-2"
                    style="color: #dd4b39"
                  ></i
                  >{{ $t("LoginGoogle") }}
                </div>
              </b-button>
            </div>

            <div class="mx-auto mb-4 text-center">
              <b-link
                :to="{ path: 'home' }"
                variant="outline-primary"
                pill
                block
                >{{ $t("Main") }}</b-link
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Register",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "قم بإنشاء حساب لدينا واكتشف المزيد حول موقعنا الإلكتروني وعروض المنتجات الشهرية الخاصة بكل فئة.",
      },
    ],
  },
  data() {
    return {
      form: {
        FirstNameI: null,
        LastNameI: null,
        MailI: null,
        UserNameI: null,
        PassI: null,
        Pass2I: null,
        terms: null,
      },
      show: false,
    };
  },
  mounted() {
    this.$gtag.event("SignUp Page", { method: "Google" });

    window.addEventListener("message", this.onMessage, false);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
  },
  computed: {
    ...mapGetters(["config"]),
  },
  validations: {
    form: {
      FirstNameI: {
        required,
        minLength: minLength(3),
      },
      LastNameI: {
        required,
        minLength: minLength(3),
      },
      PassI: {
        required,
        minLength: minLength(8),
      },
      Pass2I: {
        required,
        minLength: minLength(8),
        sameAsPassword: sameAs("PassI"),
      },
      MailI: {
        required,
        email,
        //validate Email
        async validateMail(value) {
          return axios
            .get(process.env.VUE_APP_DEVBASEURL + "/validate/mail/" + value)
            .then(function (resp) {
              return resp.data.success;
            });
        },
      },
      UserNameI: {
        required,
        minLength: minLength(5),
        //validate UserName
        async validateUserName(value) {
          return axios
            .get(process.env.VUE_APP_DEVBASEURL + "/validate/username/" + value)
            .then(function (resp) {
              return resp.data.success;
            });
        },
      },
      terms: {
        required,
      },
    },
  },
  methods: {
    login() {
      this.$router.push({ name: "Login" });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    ...mapActions(["RegisterUser", "LoginWithSocialite"]),
    Register() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.RegisterUser(this.form);
      }
    },
    RegisterWithGoogle: function () {
      const newWindow = openWindow("", "message");
      newWindow.location.href = this.config.google;
    },
    RegisterWithFacebook: function () {
      const newWindow = openWindow("", "message");
      newWindow.location.href = this.config.facebook;
    },
    onMessage(e) {
      if (e.data.token && e.data.user) {
        this.LoginWithSocialite(e.data);
        this.show = true;
        setTimeout(() => {
          this.$router.push({ name: "Home" });
        }, 2000);
      }
    },
  },
};
function openWindow(url, title, options = {}) {
  if (typeof url === "object") {
    options = url;
    url = "";
  }

  options = { url, title, width: 600, height: 720, ...options };

  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screen.top;
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    window.screen.width;
  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    window.screen.height;

  options.left = width / 2 - options.width / 2 + dualScreenLeft;
  options.top = height / 2 - options.height / 2 + dualScreenTop;

  const optionsStr = Object.keys(options)
    .reduce((acc, key) => {
      acc.push(`${key}=${options[key]}`);
      return acc;
    }, [])
    .join(",");

  const newWindow = window.open(url, title, optionsStr);

  if (window.focus) {
    newWindow.focus();
  }

  return newWindow;
}
</script>

<style scoped>
.FormImg {
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
  background: #c0484800;
  /* fallback for old browsers */
  background: linear-gradient(#fd690693, rgba(148, 72, 192, 0.281)),
    url("../assets/form.jpg");
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(#441c02fa, rgba(148, 72, 192, 0.281)),
    url("../assets/form.jpg");
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: cover;
  background-repeat: no-repeat;
}

.RegisterInner {
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 30px 1px #00000052;
}

.RegisterWrapper {
  background: #f4f4f4;
  padding: 4%;
}

@media only screen and (min-width: 320px) and (max-width: 625px) {
  .RegisterWrapper {
    padding: 16px 0;
  }
}
</style>